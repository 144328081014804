<!--
 * @Description: 全部工单
 * @Author: ChenXueLin
 * @Date: 2021-08-17 09:18:43
 * @LastEditTime: 2022-07-15 16:17:47
 * @LastEditors: ChenXueLin
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="workNo">
              <el-input
                v-model="searchForm.workNo"
                placeholder="工单号"
                title="工单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="workType">
              <e6-vr-select
                v-model="searchForm.workType"
                :data="workTypeList"
                @change="workTypeChange"
                @clear="workTypeChange"
                placeholder="工单类型"
                title="工单类型"
                clearable
                :loading="selectLoading"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="workSecondClass">
              <e6-vr-select
                v-model="searchForm.workSecondClass"
                :data="secondClassList"
                placeholder="工单二级类型"
                title="工单二级类型"
                :loading="selectLoading"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskStatusList">
              <e6-vr-select
                v-model="searchForm.taskStatusList"
                :data="workStatusList"
                placeholder="工单状态"
                :loading="selectLoading"
                title="工单状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                multiple
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createByName">
              <el-input
                v-model="searchForm.createByName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="创建工单" @click="createTask"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          ref="tableList"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span
                v-if="column.fieldName === 'workStatusName'"
                :class="[
                  'status',
                  row.workStatus === 3 || row.workStatus === 4
                    ? 'finish'
                    : row.workStatus === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                {{ row.workStatusName }}
                <!-- <el-button type="text" @click="handleGoToBillDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button> -->
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
      <!-- 创建工单弹框 -->
      <add-task-dialog
        :showAdd="showAdd"
        @closeDialog="closeDialog"
      ></add-task-dialog>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getAllTask, findDownList } from "@/api";
import { printError } from "@/utils/util";
import addTaskDialog from "@/components/workOrderManage/addTaskDialog";
export default {
  name: "allWorkOrder",
  data() {
    return {
      showAdd: false,
      searchForm: {
        workNo: "", //工单号
        workType: "", //工单类型
        workSecondClass: [], //工单二级类型
        taskStatusList: ["1", "2"], //工单状态
        createByName: "", //创建人
        createTime: [], //创建时间
        createdTimeStart: "", //创建开始时间
        createdTimeEnd: "", //创建结束时间
        pageIndex: 1,
        pageSize: 20
      },
      workTypeList: [], //工单类型下拉框
      secondWorkTypeList: [], //二级工单类型
      workStatusList: [], //工单状态
      total: 0,
      loading: false,
      selectLoading: false,
      columnData: [
        {
          fieldName: "workNo",
          display: true,
          fieldLabel: "工单号",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workTypeName",
          display: true,
          fieldLabel: "工单类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workSecondClassName",
          display: true,
          fieldLabel: "工单二级类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workStatusName",
          display: true,
          fieldLabel: "工单状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdByName",
          display: true,
          fieldLabel: "创建人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeStr",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      taskSecondClassList: [], //任务单二级类型
      serviceSecondClassList: [], //服务单二级类型
      synergySecondClassList: [], //协同单二级类型
      reviewSecondClassList: [], //回访单二级类型
      queryListAPI: getAllTask,
      refTable: "tableList"
    };
  },
  mixins: [listPage, base, listPageReszie],
  components: {
    addTaskDialog
  },
  computed: {
    secondClassList() {
      let list = [];
      if (this.searchForm.workType == 1) {
        list = this.serviceSecondClassList;
      }
      if (this.searchForm.workType == 3) {
        list = this.taskSecondClassList;
      }
      if (this.searchForm.workType == 4) {
        list = this.synergySecondClassList;
      }
      if (this.searchForm.workType == 5) {
        list = this.reviewSecondClassList;
      }
      return list;
    }
  },

  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdTimeStart = val[0];
        this.searchForm.createdTimeEnd = val[1];
      }
    }
  },
  methods: {
    handleCitySelect(val) {
      console.log(val, "val====");
    },
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList([
            "workType",
            "workStatus",
            "taskSecondClass",
            "serviceSecondClass",
            "synergySecondClass",
            "reviewSecondClass"
          ])
        ];
        let [workTypeRes] = await Promise.all(promiseList);
        //工单类型
        this.workTypeList = this.getFreezeResponse(workTypeRes, {
          path: "data.workType"
        });
        //工单状态
        this.workStatusList = this.getFreezeResponse(workTypeRes, {
          path: "data.workStatus"
        });
        //任务单二级类型
        this.taskSecondClassList = this.getFreezeResponse(workTypeRes, {
          path: "data.taskSecondClass"
        });
        //服务单二级类型
        this.serviceSecondClassList = this.getFreezeResponse(workTypeRes, {
          path: "data.serviceSecondClass"
        });
        //协同单二级类型
        this.synergySecondClassList = this.getFreezeResponse(workTypeRes, {
          path: "data.synergySecondClass"
        });
        //回访单二级类型
        this.reviewSecondClassList = this.getFreezeResponse(workTypeRes, {
          path: "data.reviewSecondClass"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 工单类型改变事件
    workTypeChange() {
      this.searchForm.workSecondClass = [];
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        if (row.workType == 1) {
          //服务单
          this.routerPush({
            name: "serviceListManage/serviceDetail",
            params: {
              refresh: true,
              serviceId: row.taskId
            }
          });
        }
        if (row.workType == 3) {
          //任务单
          this.routerPush({
            name: "taskListManage/detail",
            params: {
              refresh: true,
              taskId: row.taskId
            }
          });
        }
        if (row.workType == 4) {
          //协同单
          this.routerPush({
            name: "teamWork/teamWorkDetail",
            params: {
              refresh: true,
              synergyId: row.taskId
            }
          });
        }
        if (row.workType == 5) {
          //回访单
          this.routerPush({
            name: "visiteManage/visitDetail",
            params: {
              refresh: true,
              workNo: row.workNo
            }
          });
        }
      }
    },
    //创建工单
    createTask() {
      this.showAdd = true;
    },
    //关闭弹框
    closeDialog() {
      this.showAdd = false;
    }
  }
};
</script>
<style lang="scss" scoped></style>
